
import AddExaminer from "@/views/examiner/deployment/assign/online-assign/components/addExaminer.vue";
import { createVNode, defineComponent, getCurrentInstance, h, ref } from "vue";
import {
  EnumSpeakingType,
  ExaminerInfoModel,
  SpeakingSittingBlockEnum,
  VcsTestTimeArrangementDetail,
} from "@/api-client/client";
import moment from "moment";
import { checkAccess } from "@/utils/common";
import { VcsAssignSpkInfoModel } from "@/api-client/modal";
import { message, Modal } from "ant-design-vue";
import { _Client } from "@/api-client";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    AddExaminer,
  },
  props: {
    spkInfo: { type: VcsAssignSpkInfoModel, required: true },
    arrangementItem: { type: VcsTestTimeArrangementDetail, required: true },
  },

  setup(props, context) {
    const timeMoment = moment;
    const internalInstance = getCurrentInstance();

    const showAddExaminerModal = ref<boolean>(false);

    async function handelShowAddExaminerModal() {
      let hasAssignViewPermission = await checkAccess(
        `${internalInstance!.appContext.config.globalProperties.$pageName}:${
          internalInstance!.appContext.config.globalProperties.$actionNames
            .AssignEdit
        }`,
        props.spkInfo.regionId!
      );
      if (!hasAssignViewPermission) {
        return;
      }
      showAddExaminerModal.value = true;
    }

    async function handelDeleteAssign(row: ExaminerInfoModel) {
      let hasAssignViewPermission = await checkAccess(
        `${internalInstance!.appContext.config.globalProperties.$pageName}:${
          internalInstance!.appContext.config.globalProperties.$actionNames
            .AssignEdit
        }`,
        props.spkInfo.regionId!
      );
      if (!hasAssignViewPermission) {
        return;
      }
      const warningMessage = h("div", {}, [
        h(
          "p",
          `session: ${props.spkInfo.speakingDay} ${row.examinerHubAbbreviation}`
        ),
      ]);
      // 如果当前是在删除最后一条编排，并且该Hub,该SpeakingDay需要的考官人数是0，则删除编排之后自动回到主页面；否则还停留在Assign arrangement页面
      if (props.spkInfo.isBackDeploymentPage) {
        Modal.confirm({
          title: "Are you sure you want to delete this examiner arrangement, and back to deployment page?",
          content: warningMessage,
          icon: createVNode(ExclamationCircleOutlined),
          okText: "Yes",
          cancelText: "No",
          onOk: async () => {
            await _Client.spkDeploymentOnlineClient.deleteExaminerArrangement(
              row.examinerArrangementId
            );
            message.success("Delete Success");
            context.emit("backToDeploymentPage");
          },
        });
      } else {
        Modal.confirm({
          title: "Are you sure you want to delete this examiner arrangement?",
          content: warningMessage,
          icon: createVNode(ExclamationCircleOutlined),
          okText: "Yes",
          cancelText: "No",
          onOk: async () => {
            await _Client.spkDeploymentOnlineClient.deleteExaminerArrangement(
              row.examinerArrangementId
            );
            message.success("Delete Success");
            context.emit("getArrangement");
          },
        });
      }
    }

    function handleCloseAddExaminerModal() {
      showAddExaminerModal.value = false;
      context.emit("getArrangement");
    }

    return {
      handelShowAddExaminerModal,
      handelDeleteAssign,
      showAddExaminerModal,
      handleCloseAddExaminerModal,
    };
  },
});
