import { defineComponent, onMounted, reactive, ref, toRefs, unref } from "vue";
import AssignTable from "@/views/examiner/deployment/assign/online-assign/components/assignTable.vue";
import FooterBar from "@/components/FooterBar/index.vue";
import { EnumSpeakingType, VCSExaminerArrangementQuery, VCSExaminerArrangementRep } from "@/api-client/client";
import moment from "moment";
import { useRouter } from "vue-router";
import { VcsAssignSpkInfoModel } from "@/api-client/modal";
import { _Client } from "@/api-client";
import { addTimezoneByDbNameForce } from "@/utils/DateExtensions";

export default defineComponent({
    components: { AssignTable, FooterBar },
    setup() {
        const timeMoment = moment
        const { currentRoute, push, back } = useRouter();
        const route = unref(currentRoute);
        const searchExaminerArrangementParams: VCSExaminerArrangementQuery = new VCSExaminerArrangementQuery({
            resourceDeploymentId: route.params.resourceDeploymentId.toString()
        })

        const pageLoading = ref<boolean>(false)

        const state = reactive<{ arrangementRep: VCSExaminerArrangementRep, spkInfo: VcsAssignSpkInfoModel }>({
            arrangementRep: new VCSExaminerArrangementRep(),
            spkInfo: new VcsAssignSpkInfoModel()
        })

        async function getArrangement() {
            try {
                pageLoading.value = true
                state.arrangementRep = await _Client.spkDeploymentOnlineClient.arrangementQuery(searchExaminerArrangementParams)
                state.spkInfo = new VcsAssignSpkInfoModel(<VcsAssignSpkInfoModel>{
                    resourceDeploymentId: state.arrangementRep.resourceDeploymentId,
                    examinerHubName: state.arrangementRep.examinerHubName,
                    speakingDay: moment(addTimezoneByDbNameForce(state.arrangementRep.speakingDay!, 'Etc/GMT-0')).format('DD/MM/YYYY'),
                    speakingType: state.arrangementRep.speakingType,
                    examinerHubCityName: state.arrangementRep.examinerHubCityName,
                    regionId: state.arrangementRep.regionId,
                    isBackDeploymentPage: state.arrangementRep.isBackDeploymentPage
                })
            } finally {
                pageLoading.value = false
            }
        }

        onMounted(async () => {
            await getArrangement()
        })

        return { timeMoment, pageLoading, ...toRefs(state), getArrangement, back }
    }
})
