
import { computed, defineComponent, reactive, ref, toRefs, unref } from "vue";
import { VcsAssignSpkInfoModel } from "@/api-client/modal";
import {
  EnumSpeakingType,
  SpeakingSittingBlockEnum,
  VCSExaminerAssignCmd,
  VCSExaminerQuery,
  VCSExaminerRep,
} from "@/api-client/client";
import { _Client } from "@/api-client";
import { Modal } from "ant-design-vue";

export default defineComponent({
  props: {
    spkInfo: { type: VcsAssignSpkInfoModel, required: true },
    testTime: null,
  },
  setup(props, context) {
    const tableLoading = ref<boolean>(false);
    const saveBtnLoading = ref<boolean>(false);
    const selectTop = ref<number | null>(null);

    const state = reactive<{
      examinerList: VCSExaminerRep[];
      tableSelectedIndexList: string[];
      searchParams: VCSExaminerQuery;
    }>({
      examinerList: new Array<VCSExaminerRep>(),
      tableSelectedIndexList: new Array<string>(),
      searchParams: VCSExaminerQuery.fromJS({
        regionId: props.spkInfo.regionId,
        resourceDeploymentId: props.spkInfo.resourceDeploymentId,
        speakingSitting: <SpeakingSittingBlockEnum>props.testTime,
        speakingType: props.spkInfo.speakingType
      }),
    });

    const tableSelectRowList = computed(() => {
      return state.examinerList.filter((examiner) => {
        return rowSelection.value.selectedRowKeys.includes(
          examiner.examinerNo!
        );
      });
    });

    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.tableSelectedIndexList,
        onChange: (selectedRowKeys: string[]) => {
          state.tableSelectedIndexList = selectedRowKeys;
        },
        getCheckboxProps: (record: VCSExaminerRep) => ({
          disabled: !record.examinerStatusAvailable, // Column configuration not to be checked
        }),
      };
    });

    async function searchExaminerList() {
      try {
        tableLoading.value = true;
        state.examinerList = await _Client.spkDeploymentOnlineClient.examinerQuery(
          state.searchParams
        );
      } finally {
        tableLoading.value = false;
      }
    }

    function searchTopExaminerList() {
      try {
        tableLoading.value = true;
        state.tableSelectedIndexList = state.examinerList
          .filter((examiner) => {
            return examiner.examinerStatusAvailable;
          })
          .map((examiner) => {
            return examiner.examinerNo!;
          })
          .slice(0, selectTop.value || 0);
      } finally {
        tableLoading.value = false;
      }
    }

    function resetSearchParams() {
      state.searchParams = VCSExaminerQuery.fromJS({
        regionId: props.spkInfo.regionId,
        resourceDeploymentId: props.spkInfo.resourceDeploymentId,
        speakingSitting: <SpeakingSittingBlockEnum>props.testTime,
      });
      selectTop.value = null;
      state.examinerList = [];
    }

    function closeModal() {
      Modal.confirm({
        content:
          "Any data changed this time will not be saved. Are you sure you want to cancel without saving?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          context.emit("handleCloseAddExaminerModal");
        },
      });
    }

    async function handelAddExaminer() {
      try {
        saveBtnLoading.value = true;
        let addExaminerIdList: string[] = [];
        addExaminerIdList = unref(tableSelectRowList).map(
          (selectRow) => selectRow.examinerNo!
        );
        let vcsExaminerAssignCmd = VCSExaminerAssignCmd.fromJS({
          resourceDeploymentId: props.spkInfo.resourceDeploymentId!,
          speakingSitting: props.testTime,
          examinerNoList: addExaminerIdList,
          speakingType: props.spkInfo.speakingType
        });
        await _Client.spkDeploymentOnlineClient.assign(vcsExaminerAssignCmd);
        context.emit("handleCloseAddExaminerModal");
      } finally {
        saveBtnLoading.value = false;
      }
    }

    return {
      tableLoading,
      saveBtnLoading,
      selectTop,
      ...toRefs(state),
      tableSelectRowList,
      rowSelection,
      searchExaminerList,
      searchTopExaminerList,
      closeModal,
      handelAddExaminer,
      resetSearchParams
    };
  },
});
